.container {
    background-color: var(--color-footer-bg);
    color: var(--color-footer-font);
    padding-top: 30px;
    border-top: 1px solid #ccc;
    z-index: var(--zIndex-navBar);
}

.brandLogoWrapper {
    margin-bottom: 20px;
    margin-right: 50px;
    margin-left: 20px;
}

.brandLogo {
    width: 160px;
}

.menu {
    margin: 0 20px;
}

.allRightsReserved {
    font-size: 12px;
    margin-bottom: 20px;
}

.spacer {
    flex: 0.5;
}

@media (max-width: 600px) {
    .logos {
        min-width: calc(100vw - 40px);
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }
}
