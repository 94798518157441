.shareButtons {
    display: flex;
}

.shareButtons button {
    margin-left: 5px;
}

.shareButtonsMobile {
    display: none;
}

.shareButtonsMobile button {
    margin-right: 5px;
}

.askUs {
    font-size: 16px;
    text-decoration: underline;
}

@media (max-width: 800px) {
    .shareButtons {
        display: none;
    }
}

@media (max-width: 800px) {
    .shareButtonsMobile {
        display: flex;
    }
}
