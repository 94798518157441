.container {
    position: absolute;
    left: 0;
    right: 0;
    color: #201a33;
    animation: subGroupOpening 0.3s ease-in;
}

.arrow {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 12px solid #fff;
    z-index: 10;
}

.bodyWrapper {
    position: absolute;
    top: 0;
    padding-top: 12px;
}

.body {
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
}

.subGroup {
    padding: 20px 20px 20px 40px;
    min-width: 200px;
    font-size: 12px;
}

.title {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;
}

@keyframes subGroupOpening {
    from {
        opacity: 0.1;
        transform: perspective(9cm) rotateX(-18deg);
    }
    60% {
        transform: perspective(9cm) rotateX(0deg);
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 1500px) {
    .subGroup > * {
        font-size: 12px;
        min-width: 140px;
    }

    h4 {
        font-size: 12px;
    }
}
