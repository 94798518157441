.container {
    color: #201A33 !important;
    opacity: 0.6;
    cursor: pointer;
    padding: 3px 0;
    flex-shrink: 0;
    transition: all 0.3s;
}

.container_highlighted {
    color: #68cc9a !important;
    transform: translateX(-6px);
}

.item {
    max-width: 100%;
}

@media (hover) {
    .container:hover {
        opacity: 1;
    }
}