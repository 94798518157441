.container {
    font-weight: bold;
    font-size: 20px;
    color: var(--color-primary);
}

.contained {
    background-color: var(--color-primary);
    color: #fff;
    border-radius: var(--border-radius);
    padding: 0 12px;
    display: inline-block;
}
