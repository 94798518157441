.container {
    position: fixed;
    bottom: 96px;
    right: -100%;
    background: white;
    border-radius: 5px;
    padding: 24px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    z-index: var(--zIndex-popup);
    animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
    100% {
        right: 16px;
    }
}

.closeIcon {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    padding: 4px;
}
