.container {
    background-color: #fff !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease-in-out !important;
    padding: 8px !important;
}

@media (hover) {
    .container:hover {
        transform: scale(1.1);
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    }
}
