.container {
    padding: 20px;
    border-radius: var(--border-radius);
    border: solid 2px transparent;
    transition: all 0.3s ease;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    margin-bottom: 10px;
}

.selectable {
    cursor: pointer;
}

.selected {
    border-color: var(--color-primary);
}

.selectable:hover,
.selected {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
}

.radio {
    margin-right: 20px;
}

.brand {
    background-color: #000;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 10px;
    color: #fff;
    border-radius: var(--border-radius);
    padding: 0 8px;
}
