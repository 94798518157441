.container {
    background-color: white;
}

.bottomInfo {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: var(--color-firstLvl-bg-dark);
    height: 2px;
    width: 100%;
}

.username {
    color: var(--color-primary);
}

.bid {
    margin: 60px 0 !important;
}