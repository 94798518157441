.container {
    border-radius: var(--border-radius);
    background-color: var(--color-secondLvl-bg);
    font-size: 14px;
    padding: 5px;
    cursor: pointer;
}

.disabled {
    cursor: default;
    background-color: rgba(0, 0, 0, 0.04);
}
