.container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100vh;
    }
}