@font-face {
    font-family: 'Poppins';
    src: url(/static/assets/Poppins-Regular.1421a94e.otf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/assets/Poppins-Thin.85a73d99.otf);
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/assets/Poppins-Bold.b5c5cbad.otf);
    font-weight: bold;
    font-style: normal;
}