:root {
    --color-primary: #0052cb;
    --color-primary-darker: #3a518c;
    --color-primary-lighter: #5ca4d1;
    --color-secondary: #ff000a;
    --color-tertiary: #ffa34d;
    --color-tertiary-alt: rgba(254, 220, 8, 0.71);
    --color-quaternary: #f67575;
    --color-success: #0ead69;
    --color-inProgress: #ee4266;
    --color-canceled: #6103c2;
    --color-buyNow: #fa731e;

    --color-text: #2b3f57;
    --color-text-fade: rgba(43, 63, 87, 0.74);
    --color-text-on-primary: #fff;

    --color-primary-bg: #1e1a33;
    --color-tertiary-bg: #fafbfd;
    --color-tertiary-text: #92a2ba;
    --color-secondary-bg: #f2f7fa;
    --color-firstLvl-bg: #fafafa;
    --color-firstLvl-bg-dark: #e0e0e0;
    --color-secondLvl-bg: #f5f6fa;
    --color-secondLvl-dark-bg: #dce0e9;
    --color-thirdLvl-bg: #fff;

    --color-warning: var(--color-tertiary);
    --color-error: #ff3e1d;
    --color-delete: #ff3e1d;

    --color-border: #68cc9a;

    --color-header-bg: #ffff;
    --color-header-font: #111;
    --color-footer-bg: var(--color-primary);
    --color-footer-font: #f1f1f1;
}
