.container {
    margin-right: 10%;
    margin-left: 10%;
    min-width: 40%;
    display: flex;
    justify-content: flex-end;
}

.searchInput {
    max-width: 320px;
    background-color: white;
    border-color: var(--color-primary-darker);
    position: relative;
    .icon {
        color: var(--color-primary);
    }
}

.searchInput:active,
.searchInput:focus {
    border-color: var(--color-secondary);
}

.searchResult {
    position: absolute;
    max-width: 40%;
    z-index: 850;
}

.background {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    right: 0;
}

@media (max-width: 850px) {
    .container {
        margin: 0;
    }
    .searchResult {
        max-width: inherit;
    }
}
