.container {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 10px;
    border-radius: var(--border-radius);
    padding: 0 8px;
}

.accepted {
    background-color: var(--color-success);
    color: #fff;
}

.declined {
    background-color: var(--color-error);
    color: #fff;
}
