.container {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 10px;
    border-radius: var(--border-radius);
    padding: 0 8px;
}

.draft {
    background-color: var(--color-tertiary-text);
}

.approved {
    background-color: var(--color-success);
    color: #fff;
}

.inProgress {
    background-color: var(--color-inProgress);
    color: #fff;
}

.closed {
    background-color: #000;
    color: #fff;
}
.canceled {
    background-color: var(--color-canceled);
    color: #fff;
}

.buyNow {
    background-color: var(--color-buyNow);
    color: #fff;
}

.cancelRequested {
    font-style: italic;
}

.paid {
    background-color: #0ead69;
    color: white;
}
