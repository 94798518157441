.container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: var(--zIndex-mobileMenu);
    background-color: var(--color-primary);
    color: var(--color-text-on-primary);
    padding: 20px;
    overflow: auto;
    text-align: center;
    transform: translateX(100vw);
    transition: all 0.4s ease-in-out;
}

.opened {
    display: block;
    transform: translateX(0);
}

.logo {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
}

.closeIcon {
    position: absolute !important;
    top: 10px;
    right: 20px;
}