.container {
    padding: 0 0 0 20px;
    border-left: solid 1px #fff;
    flex: 1;
    justify-content: center;
    height: 100%;
}

.innerContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 185px;
    margin-right: -10px;
}

.link {
    color: #fff !important;
    font-size: 40px;
    margin: 0 10px;
    transition: all 0.3s;
}

@media (hover) {
    .link:hover {
        transform: scale(1.1);
    }
}

@media (max-width: 959px) {
    .container {
        border: none;
        justify-content: flex-start;
        padding-left: 10px;
    }
}

@media (max-width: 800px) {
    .container {
        flex-shrink: 0;
        min-width: calc(100vw - 40px);
        margin: 0;
        padding: 0;
    }

    .innerContainer {
        width: auto;
    }
}

@media (max-width: 768px) {
    .container {
        justify-content: center;
    }
}
