.container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.82);
    color: #fff;
    font-size: 18px;
    z-index: var(--zIndex-overlay);
}
