.container {
    position: relative;
}

.container_separator:before {
    content: "";
    width: 2px;
    top: 50%;
    margin-top: -11px;
    position: absolute;
    display: block;
    height: 20px;
    opacity: 0.9;
    background-color: #fff;
}

.menu {
    color: var(--color-header-font) !important;
    cursor: pointer;
    padding: 10px;
    height: 82px;
    position: relative;
    font-size: 13px;
}

.menuHighlighted {
    background-color: red !important;
}

.text,
.textHighlighted {
    font-weight: bold;
    max-width: 200px;
}

.text {
    padding: 5px 0;
    border-bottom: solid 2px transparent;
    transition: all 0.3s;
}

.textHighlighted {
    font-weight: 900;
    padding: 2px;
}

.image {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
}

.menu_active .text {
    border-color: var(--color-primary-lighter) !important;
}

.menuSubtitle {
    position: absolute;
    bottom: 13px;
    font-size: 15px;
    font-weight: normal;
    color: #68cc9a !important;
    text-decoration: none !important;
}

.menuSubIcon {
    color: #fff;
    margin-right: 10px;
    font-size: 18px;
    display: block;
}

@media (hover) {
    .menu:hover .text {
        border-color: var(--color-primary-darker);
    }
}

@media (max-width: 1300px) {
    .menu {
        font-size: 13px;
        margin: 6px;
        padding: 6px;
    }
}
