.title {
    font-size: 22px;
}

.subtitle {
    font-size: 16px;
    text-decoration: underline;
}

@media (max-width: 767px) {
    .titleContainer {
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }
}