@import '../../../_css/variables/variables.css';

.container_withBottomPadding .textContainer {
    padding-bottom: 220px;
}

.container_withBottomPadding .navigationDots {
    bottom: 150px;
}

.slide {
    position: relative;
    height: calc(60vh - 110px);
    max-height: 600px;
    min-height: 300px;
    background-size: cover;
    background-position: center center;
    flex-shrink: 0;
}

.textContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 20px 80px;
}

.title, .text {
    width: 66%;
}

.title {
    font-size: 55px;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 20px;
}

.navigationDots {
    position: absolute;
    color: #FFF;
    bottom: 10px;
    left: 0;
    right: 0;
}

.navigationDot {
    cursor: pointer;
    z-index: 1;
}

.arrowRight, .arrowLeft {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    cursor: pointer;
    z-index: 1;
    color: #FFF;
}

.arrowRight {
    right: 24px;
}

.arrowLeft {
    left: 24px;
}

@media (max-width: 1280px) {
    .arrowRight, .arrowLeft {
        display: none;
    }
}

@media (max-width: 1024px) {
    .textContainer {
        padding-top: 100px;
    }

    .title, .text {
        width: 100% !important;
    }

    .title {
        font-size: 38px;
    }
}

@media (max-width: 768px) {
    .textContainer {
        padding-top: 20px;
    }

    .title {
        font-size: 28px;
    }
}


@media (max-width: 1000px) {
    .slide {
        height: calc(100vh - 72px);
    }
}