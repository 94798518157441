.container {
    cursor: pointer;
}

.soldThumbnail,
.mainPhoto {
    position: relative;
    height: 374px;
    width: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 3px;
}

.soldThumbnail {
    position: absolute;
    width: 100%;
    background: black;
    color: white;
    font-weight: bold;
    font-size: 32px;
    text-transform: uppercase;
    opacity: 0.66;
    text-align: center;
}

.secondaryPhoto {
    height: 122px;
    width: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 3px;
}

.seeAllPhotos {
    position: absolute;
    bottom: 20px;
    right: 15px;
    z-index: 150;
}
