.container {
    display: inline-block;
    border-radius: var(--border-radius);
    font-size: 16px;
}

.dark,
.done {
    background-color: var(--color-primary-bg);
    color: var(--color-text-on-primary);
    padding: 3px 10px;
}

.almostDone {
    background-color: var(--color-secondary);
    font-weight: bold;
    color: var(--color-text-on-primary);
    padding: 3px 10px;
}
