@font-face {
    font-family: 'aig';
    src: url(/static/assets/aig.8ae2f9bf.ttf) format('truetype'),
    url(/static/assets/aig.4e74fdb3.woff) format('woff'),
    url(/static/assets/aig.3e5c041f.svg#aig) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="aig-"], [class*=" aig-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'aig' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.aig-teamviewer:before {
    content: "\e97d";
}

.aig-approved:before {
    content: "\e905";
}

.aig-calculator-2:before {
    content: "\e906";
}

.aig-headphones:before {
    content: "\e907";
}

.aig-team1:before {
    content: "\e908";
}

.aig-manager:before {
    content: "\e909";
}

.aig-symbols:before {
    content: "\e90a";
}

.aig-accounting1:before {
    content: "\e90b";
}

.aig-customer-support:before {
    content: "\e90c";
}

.aig-assets:before {
    content: "\e90d";
}

.aig-file-2:before {
    content: "\e90e";
}

.aig-language:before {
    content: "\e90f";
}

.aig-checklist:before {
    content: "\e910";
}

.aig-team-1:before {
    content: "\e911";
}

.aig-document:before {
    content: "\e912";
}

.aig-file-3:before {
    content: "\e913";
}

.aig-accounting-11:before {
    content: "\e914";
}

.aig-test:before {
    content: "\e915";
}

.aig-support:before {
    content: "\e916";
}

.aig-contract1:before {
    content: "\e917";
}

.aig-process1:before {
    content: "\e918";
}

.aig-files:before {
    content: "\e919";
}

.aig-file-4:before {
    content: "\e91a";
}

.aig-calculator-1:before {
    content: "\e91b";
}

.aig-employees:before {
    content: "\e91c";
}

.aig-domain:before {
    content: "\e91d";
}

.aig-implement:before {
    content: "\e91e";
}

.aig-text-format:before {
    content: "\e91f";
}

.aig-network:before {
    content: "\e920";
}

.aig-file:before {
    content: "\e921";
}

.aig-document-1:before {
    content: "\e922";
}

.aig-calculator:before {
    content: "\e923";
}

.aig-dollar:before {
    content: "\e924";
}

.aig-planet-earth:before {
    content: "\e925";
}

.aig-content:before {
    content: "\e926";
}

.aig-mathematics:before {
    content: "\e927";
}

.aig-loan:before {
    content: "\e928";
}

.aig-file-1:before {
    content: "\e929";
}

.aig-presentation1:before {
    content: "\e92a";
}

.aig-workflow:before {
    content: "\e92b";
}

.aig-meeting-room:before {
    content: "\e92c";
}

.aig-skyline:before {
    content: "\e92d";
}

.aig-bank-account:before {
    content: "\e92e";
}

.aig-profits:before {
    content: "\e92f";
}

.aig-accounting-1:before {
    content: "\e930";
}

.aig-bar-chart:before {
    content: "\e931";
}

.aig-round-table:before {
    content: "\e932";
}

.aig-mortgage-loan:before {
    content: "\e933";
}

.aig-signing-the-contract:before {
    content: "\e934";
}

.aig-process:before {
    content: "\e935";
}

.aig-resume:before {
    content: "\e936";
}

.aig-conference:before {
    content: "\e937";
}

.aig-contract:before {
    content: "\e938";
}

.aig-building-1:before {
    content: "\e939";
}

.aig-budget:before {
    content: "\e93a";
}

.aig-accounting:before {
    content: "\e93b";
}

.aig-rocket-launch:before {
    content: "\e93c";
}

.aig-calendar:before {
    content: "\e93d";
}

.aig-income:before {
    content: "\e93e";
}

.aig-user:before {
    content: "\e93f";
}

.aig-office-building-1:before {
    content: "\e940";
}

.aig-conversation:before {
    content: "\e941";
}

.aig-suitcase:before {
    content: "\e942";
}

.aig-presentation:before {
    content: "\e943";
}

.aig-clipboard:before {
    content: "\e944";
}

.aig-browser:before {
    content: "\e945";
}

.aig-chart:before {
    content: "\e946";
}

.aig-money-2:before {
    content: "\e947";
}

.aig-accounting-2:before {
    content: "\e948";
}

.aig-presentation-1:before {
    content: "\e949";
}

.aig-analysis:before {
    content: "\e94a";
}

.aig-group:before {
    content: "\e94b";
}

.aig-handshake:before {
    content: "\e94c";
}

.aig-polling:before {
    content: "\e94d";
}

.aig-hand-shake:before {
    content: "\e94e";
}

.aig-desktop:before {
    content: "\e94f";
}

.aig-darts:before {
    content: "\e950";
}

.aig-data-analysis:before {
    content: "\e951";
}

.aig-business-report:before {
    content: "\e952";
}

.aig-suitcase-1:before {
    content: "\e953";
}

.aig-pie-chart-2:before {
    content: "\e954";
}

.aig-safe-box:before {
    content: "\e955";
}

.aig-shop:before {
    content: "\e956";
}

.aig-meeting:before {
    content: "\e957";
}

.aig-target:before {
    content: "\e958";
}

.aig-calendar-1:before {
    content: "\e959";
}

.aig-report-1:before {
    content: "\e95a";
}

.aig-diagram-1:before {
    content: "\e95b";
}

.aig-money:before {
    content: "\e95c";
}

.aig-relations:before {
    content: "\e95d";
}

.aig-organization-chart:before {
    content: "\e95e";
}

.aig-laptop:before {
    content: "\e95f";
}

.aig-pie-chart-1:before {
    content: "\e960";
}

.aig-report:before {
    content: "\e961";
}

.aig-office-building:before {
    content: "\e962";
}

.aig-france:before {
    content: "\e963";
}

.aig-city:before {
    content: "\e964";
}

.aig-portfolio:before {
    content: "\e965";
}

.aig-building:before {
    content: "\e966";
}

.aig-france-1:before {
    content: "\e967";
}

.aig-video-call:before {
    content: "\e968";
}

.aig-analytics:before {
    content: "\e969";
}

.aig-team:before {
    content: "\e96a";
}

.aig-user-1:before {
    content: "\e96b";
}

.aig-monitor:before {
    content: "\e96c";
}

.aig-verification:before {
    content: "\e96d";
}

.aig-online:before {
    content: "\e96e";
}

.aig-analysis-1:before {
    content: "\e96f";
}

.aig-money-1:before {
    content: "\e970";
}

.aig-high:before {
    content: "\e971";
}

.aig-debit-card:before {
    content: "\e972";
}

.aig-bar-chart-1:before {
    content: "\e973";
}

.aig-teacher:before {
    content: "\e974";
}

.aig-goal:before {
    content: "\e975";
}

.aig-stats:before {
    content: "\e976";
}

.aig-customer-service:before {
    content: "\e977";
}

.aig-contract-1:before {
    content: "\e978";
}

.aig-analysis-2:before {
    content: "\e979";
}

.aig-pie-chart:before {
    content: "\e97a";
}

.aig-diagram:before {
    content: "\e97b";
}

.aig-lamp:before {
    content: "\e97c";
}

.aig-youtube:before {
    content: "\e900";
}

.aig-instagram:before {
    content: "\e901";
}

.aig-linkedin:before {
    content: "\e902";
}

.aig-facebook:before {
    content: "\e903";
}

.aig-twitter:before {
    content: "\e904";
}