.container {
    display: block;
    width: 100%;
    color: var(--color-text) !important;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    padding: 10px;
    background-color: white;
}

.containerIsClosed {
    flex-direction: column;
    align-self: stretch;
}

.soldThumbnail,
.thumbnail {
    height: 193px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: var(--border-radius);
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
}

.smallThumbnail {
    height: 100px;
}

.soldThumbnail {
    position: absolute;
    width: 100%;
    background: black;
    color: white;
    font-weight: bold;
    font-size: 32px;
    text-transform: uppercase;
    opacity: 0.66;
    text-align: center;
}

@media (max-width: 600px) {
    .soldThumbnail {
        font-size: 22px;
    }
}

.title {
    font-weight: bold;
    height: 35%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.titleIsClosed {
    line-height: 1;
}

.lastBid {
    color: var(--color-primary);
    font-size: 12px;
    font-style: italic;
}

.content {
    position: relative;
}

.watchBtn {
    position: absolute;
    right: 5px;
    bottom: -15px;
}

.mail {
    position: absolute;
    left: 50px;
    bottom: -15px;
}

.mailBtn {
    background-color: #fff !important;
    color: #bd2424 !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease-in-out !important;
    padding: 8px !important;
}

.facebook {
    position: absolute;
    left: 5px;
    bottom: -15px;
}

.facebookBtn {
    background-color: #fff !important;
    color: #1877f2 !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease-in-out !important;
    padding: 8px !important;
}

.copyLink {
    position: absolute;
    left: 95px;
    bottom: -15px;
}

.copyLinkBtn {
    background-color: #fff !important;
    color: #2b2b2b !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease-in-out !important;
    padding: 8px !important;
}

.contentHidden {
    display: none;
}

.infoIsClosed {
    height: 130px;
}

.isClosed {
    flex: 1;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
}

.buyNow {
    flex: 1;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
}

.buyNowLink:hover {
    text-decoration: underline;
}

@media (hover) {
    .facebookBtn:hover {
        transform: scale(1.1);
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    }
    .mailBtn:hover {
        transform: scale(1.1);
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    }
    .copyLinkBtn:hover {
        transform: scale(1.1);
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    }
    .container:hover {
        transform: scale(1.02);
    }
    .container:hover .thumbnail {
        opacity: 0.8;
    }
}

@media (min-width: 900px) {
    .hiddenDesktop {
        display: none;
    }
}
