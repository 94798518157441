.mobileContainer {
    display: none;
}

.arrowBottomIcon {
    margin-left: 1px;
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 27px;
}

@media (max-width: 1000px) {
    .container {
        display: none;
    }

    .mobileContainer {
        display: block;
    }
}

@media (max-width: 1300px) {
    .arrowBottomIcon {
        right: -6px;
        top: 33px;
    }
}

.menuAuction {
    position: relative;
}
.subMenuAuction {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    top: 55px;
    background-color: #fafafa;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    transition: opacity 232ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 155ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform-origin: 12.4219px 26px;
    box-sizing: border-box;
    white-space: pre-wrap;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}