.container {
    width: 100%;
    height: calc(100vh - 260px); /*screen - header - title - footer*/
    overflow-y: auto;
    position: sticky;
    top: 85px;
    align-self: stretch;
}

.container::-webkit-scrollbar {
    width: 5px;
}

.container::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 5px;
}

.title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
}

