.cross {
    position: absolute;
    right: 0;
    z-index: 12000;
}

.crossPlaceHolderSmall {
    padding-top: 30px;
}

.crossPlaceHolderLarge {
    padding-top: 50px;
}

@media (max-width: 800px) {
    .bottomMobileCross {
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
    }
}
