.container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.hiddenDesktop {
    display: none;
}

@media screen and (max-width: 600px) {
    .hiddenDesktop {
        display: unset;
    }

    .hiddenMobile {
        display: none;
    }
}
