.MuiDropzoneArea-text {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 18px !important;
}

.MuiDropzoneArea-icon {
    height: 24px !important;
    width: 24px !important;
}

.MuiDropzoneArea-root {
    min-height: unset !important;
}

.MuiDropzoneArea-root:focus, .MuiDropzoneArea-root:hover {
    border-color: #0052CB !important;
}