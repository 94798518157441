.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: white;
    z-index: var(--zIndex-overlay);
}

.tabs {
    padding-right: 85px;
}

@media (max-width: 800px) {
    .tabs {
        padding-right: 0;
    }
}
