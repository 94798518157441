.container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 15000;
    background: var(--color-secondLvl-dark-bg);
    padding: 20px;
    font-size: 12px;
    animation: appear 0.3s ease;
}

.innerContainer {
    margin: 0 auto;
    max-width: 80%;
    flex-wrap: wrap;
}

.left {
    flex: 1 1 auto;
    margin-right: 30px;
    font-size: 15px;
}

.right {
    margin: auto;
}

.exitActive {
    transition: all 0.2s ease;
    transform: translateY(100%);
}

@keyframes appear {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes topAppear {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@media (max-width: 560px) {
    .innerContainer {
        max-width: none;
    }
}