.title {
    font-size: 22px;
}

.subtitle {
    font-size: 16px;
    text-decoration: underline;
}

.sideBarAbsolute {
    position: absolute;
    right: 20px;
    top: 0;
}

@media (max-width: 1540px) {
    .sideBarAbsolute {
        display: none;
    }
}

@media (min-width: 1540px), (max-width: 900px) {
    .hiddenXsXl {
        display: none;
    }
}

@media (max-width: 767px) {
    .titleContainer {
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }
}
