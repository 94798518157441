.container {
    max-width: 200px;
    position: relative;
    margin: 10px;
}

.selectedContainer {
    border-radius: 4px;
    border: 3px solid var(--color-primary);
    box-shadow: var(--color-primary) 0px 3px 8px;
}

.delete {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    background-color: var(--color-secondLvl-dark-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.deleteIcon {
    font-size: 14px !important;
}

@media (hover) {
    .delete:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
        transform: scale(1.3);
    }
}
