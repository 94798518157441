.container {
    padding: 20px;
    border-radius: var(--border-radius);
    border: solid 2px transparent;
    transition: all 0.3s ease;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    margin-bottom: 10px;
    color: var(--color-text) !important;
}

@media (hover) {
    .container:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    }
}