.tabContent {
    position: relative;
}

.tab {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.secondTab .tab:first-child {
    position: absolute;
    left: -100vw;
    opacity: 0;
}

.firstTab .tab:last-child {
    position: absolute;
    left: 100vw;
    opacity: 0;
}
