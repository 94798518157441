.container {
    cursor: pointer;
    text-decoration: underline;
    user-select: none;
    display: inline-block;
}

.small {
    font-size: 14px;
}

.delete {
    color: var(--color-delete);
    text-decoration: underline;
}
