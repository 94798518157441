@import "../../../_css/variables/variables.css";

.container,
.wrapper,
.placeholder {
    height: 85px;
}

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--zIndex-navBar);
    background-color: var(--color-header-bg);
    color: var(--color-header-font);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.logo {
    font-size: 28px;
    font-weight: 900;
    color: var(--color-primary);
    text-decoration: none;
    margin-right: 20px;
}

.headerLogo {
    margin-top: 10px;
}

.slogan {
    margin: 0 10px;
    font-size: 20px;
}

@media (max-width: 1300px) {
    .headerLogo {
        margin-right: 20px;
        margin-top: 18px;
        width: 120px !important;
    }
}

@media (max-width: 1000px) {
    .container,
    .wrapper,
    .placeholder {
        height: 72px;
    }
    .headerLogo {
        margin-top: 13px;
    }
}
