.termsCheckbox {
    display: flex;
    margin-top: 48px;
    align-items: center;
    justify-content: center;
}

.termsCheckbox > label {
    flex: 1;
    font-size: 14px;
}

.terms {
    font-size: 12px;
}

.terms:first-child {
    font-weight: 900;
    color: red;
}
