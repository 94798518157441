.title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.link {
    font-size: 14px;
    display: block;
    margin: 10px 0;
    color: #FFF !important;
}

@media (hover) {
    .link:hover {
        text-decoration: underline;
    }
}