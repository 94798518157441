.container {
    padding: 30px 0;
}

.light {
    color: var(--color-text);
}

.dark {
    color: var(--color-text-on-primary);
    background: var(--color-primary);
}

.secondary {
    color: var(--color-text-on-primary);
    background: var(--color-secondary);
}
