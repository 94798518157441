.arrow {
    border-radius: 50%;
    background-color: white;
    height: 50px;
    width: 50px;
    cursor: pointer;
    position: absolute;
    color: #000000;
    margin-top: -25px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .12);
}

.arrowLeft {
    top: 50%;
    left: 0;
}

.arrowRight {
    top: 50%;
    right: 0;
}