.container {
    padding: 30px 0;
}

.light {
    color: #2B3F57;
}

.dark {
    color: #FFF;
    background: linear-gradient(180deg, #1E1A33, #4D4964);
}