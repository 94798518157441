.container {
    padding-right: 96px;
}

.body {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
}

.icon {
    color: var(--color-primary);
}
